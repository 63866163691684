import React from 'react';
import NumberFormat from 'react-number-format';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from '../components/Layout';


import Footer from '../components/Footer';
import withLocation from '../withLocation';

const ResultPage = ({ search }) => {
  const {id} = search;
  let result = [];
  if (id) {
    result = Buffer.from(id, 'base64').toString().split(';');
  }

  return (
    <Layout>
      <header className="result d-flex">
        <div className="container text-center my-auto">
          <div className="row">
            <div className="col">
                <NumberFormat 
                    value={result[1]* 1000}
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <h1 className="mb-2">{value}</h1>}
                />
                <h3 className="mb-1">là số tiền bạn đã chi cho thời trang</h3>
                <h4 className="mb-2">/ năm</h4>
            </div>
          </div>
          <div className="row" style={{marginTop: '1rem'}}>
            <div className="col">
                <NumberFormat 
                    value={result[0]* 1000}
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={value => <h1 className="mb-2">{value}</h1>}
                />
                <h3 className="mb-1">là lãng phí</h3>
                <h4 className="mb-2">mà vẫn "không có gì để mặc"</h4>
            </div>
          </div>

          <div className="row">
            <div className="col drobebox">
                <h3 className="mb-1 headline">Sở hữu ít hơn, trải nghiệm nhiều hơn</h3>
                <OutboundLink href='https://drobebox.com'>Tìm hiểu thêm</OutboundLink>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </header>
      <Footer />
    </Layout>
  );
}

export default withLocation(ResultPage);
